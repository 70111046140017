import React, { useEffect, useState } from "react";
import Firebase from "firebase";
import uncleDonImage from "./uncledon.png";
import "./App.css";

var firebaseConfig = {
  apiKey: "AIzaSyBL-8g7k65g_Sk9RsFcWWMw6lGvvvJBzZ8",
  authDomain: "uncledonsays.firebaseapp.com",
  databaseURL: "https://uncledonsays.firebaseio.com",
  projectId: "uncledonsays",
  storageBucket: "uncledonsays.appspot.com",
  messagingSenderId: "135014490867",
  appId: "1:135014490867:web:79100d377b7c2b88840d86",
};

const app = Firebase.initializeApp(firebaseConfig);
const firebaseDB = app.database();
const firebaseRef = firebaseDB.ref("Sayings");
console.log("firebaseRef: ", firebaseRef);

function App() {
  const [saying, setSaying] = useState("");
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    console.log("--------------------------");
    console.log("imageUrl has changed");
    async function getImage() {
      const storageReference = Firebase.storage().ref("KayakKid.jpg");
      console.log("storageReference: ", storageReference);
      const url = await storageReference.getDownloadURL();
      console.log("url for Dons Image:", url);
      setImage(url);
    }
    getImage();
    // setImageUrl(imageUrl);
    // setImage(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    console.log("--------------------------");
    console.log("Equivalent of ComponentDidMount");
    async function getImage() {
      const storageReference = Firebase.storage().ref("KayakKid.jpg");
      console.log("storageReference: ", storageReference);
      const url = await storageReference.getDownloadURL();
      console.log("url for Dons Image:", url);
      setImage(url);
    }
    getImage();
    firebaseRef.on("value", (querySnapShot) => {
      let data = querySnapShot.val() ? querySnapShot.val() : {};
      console.log("data: ", data);
      const { DonSays } = data;
      const { ImageUrl } = data;
      setSaying(DonSays);
      setImageUrl(ImageUrl);
      setImage(ImageUrl);
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={uncleDonImage} className="App-logo" alt="uncle don" />
        <p style={{ color: "black" }}>Uncle Don Says:</p>
        <img src={image} className="uncledonimage" alt="uncle don" />
        <p style={{ color: "black", fontWeight: "bold" }}>{saying}</p>
      </header>
    </div>
  );
}

export default App;
